body {
	color: #1F271B;
}

#root {
	height: 100%;
}

h1,h2,h3,h4,h5 {
	margin: 0px;
}

h1 {
	font-size: 2.5em;
}
h2 {
	font-size: 2.0em;
}
h3 {
	font-size: 1.7em;
}
h4 {
	font-size: 1.3em;
}
h5 {
	font-size: 1.2em;
}
